$.fn.openPopup = function(){
    if($(this).length < 1){
        return;
    }
    $('body').css('overflow','hidden');
    $('.mask').fadeIn(300);
    $(this).fadeIn(400);
    $(this).trigger('openPopup');
}
$.fn.closePopup = function(){
    $('body').css('overflow','visible');
    $('.popup').fadeOut(400);
    $('.mask').fadeOut(500);
    $(this).trigger('closePopup');
}

$('body').on('click', 'button[data-popup], a[data-popup]', function() {
    const id = $(this).attr('data-popup');
    $('.popup[data-popup="'+ id +'"]').openPopup();
    return false;
});

$('.popup__close').on('click', function() {
    $(this).closePopup();
});