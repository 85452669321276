$('.accordion__btn').on('click', function() {
  const target = $(this).parent().parent().attr('id');
  setTimeout(function(){
    scrollTo(target);
  }, 600);
});

function scrollTo(id) {
  $('html, body').animate({
    scrollTop: $('#'+id).offset().top - 80
  }, 1000);
}

export default scrollTo