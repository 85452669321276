$('.h-toolbar__item--menu').on('click', function(){
  $(".hamburger").toggleClass("active");
  $(".menu").fadeToggle();
  $('body').toggleClass('menu--active');
});

$('.menu__top-wrapper .menu__top-list button').on('click', function() {
  if(!$(this).parent().hasClass('hasSubItem--active')) {
    $(this).parent().addClass('hasSubItem--active');
    $(this).next().slideDown(600);
  } else {
    $(this).parent().removeClass('hasSubItem--active');
    $(this).next().slideUp(600);
  }
});