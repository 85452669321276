
//script for animation on scroll
(function (H) {
    var pendingRenders = [];
    function isElementInViewport(el) {

        var rect = el.getBoundingClientRect();

        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (
                window.innerHeight ||
                document.documentElement.clientHeight
            ) &&
            rect.right <= (
                window.innerWidth ||
                document.documentElement.clientWidth
            )
        );
    }

    H.wrap(H.Series.prototype, 'render', function deferRender(proceed) {
        var series = this,
            renderTo = this.chart.container.parentNode;

        // It is appeared, render it
        if (isElementInViewport(renderTo) || !series.options.animation) {
            proceed.call(series);

        // It is not appeared, halt renering until appear
        } else  {
            pendingRenders.push({
                element: renderTo,
                appear: function () {
                    proceed.call(series);
                }
            });
        }
    });

    function recalculate() {
        pendingRenders.forEach(function (item) {
            if (isElementInViewport(item.element)) {
                item.appear();
                H.erase(pendingRenders, item);
            }
        });
    }

    if (window.addEventListener) {
        ['DOMContentLoaded', 'load', 'scroll', 'resize']
            .forEach(function (eventType) {
                addEventListener(eventType, recalculate, false);
            });
    }

}(Highcharts));




Highcharts.setOptions({
    lang: {
        decimalPoint: ',',
        thousandsSep: ' ',
        numericSymbols: null,
        months: [ "01","02","03","04","05","06","07","08","09","10","11","12"],
            printChart: 'Wydrukuj',
            downloadPNG: 'Pobierz PNG',
            downloadJPEG: 'Pobierz JPEG',
            downloadPDF: 'Pobierz PDF',
            downloadSVG: "Pobierz SVG",
            downloadCSV: "Pobierz CSV",
            downloadXLS: "Pobierz XLS",
            viewData: "Pokaż dane",
            openInCloud: "Otwórz w edytorze",
    }
});

function recursiveIsset(object, path) {
    var result = true;
    path.forEach(function (key) {
        if (typeof (object[key]) === 'undefined') {
            result = false;
            return false;
        }
        object = object[key];
    });
    return result;
}

//animations
Math.easeOutBounce = function (pos) {
    if ((pos) < (1 / 2.75)) {
        return (7.5625 * pos * pos);
    }
    if (pos < (2 / 2.75)) {
        return (7.5625 * (pos -= (1.5 / 2.75)) * pos + 0.75);
    }
    if (pos < (2.5 / 2.75)) {
        return (7.5625 * (pos -= (2.25 / 2.75)) * pos + 0.9375);
    }
    return (7.5625 * (pos -= (2.625 / 2.75)) * pos + 0.984375);
};

$('[data-chart]').each(function (k, chartElement) {
    try {
        var chartObj = $(chartElement).data('chart');
       
        //function: plotOptions.columnrange.formater
        if (recursiveIsset(chartObj, ['plotOptions', 'columnrange', 'formater'])) {
            var skip = chartObj.plotOptions.columnrange.formater;
            chartObj.plotOptions.columnrange.formater = function () {
                var result = this.point.low - this.point.high;
                result = result.toFixed(1);
                if (skip.indexOf(this.key.name) >= 0) {
                    if (this.y === this.point.low)
                        return '<span style="color:black;">' + result + '</span>';
                } else if (this.y === this.point.high)
                    return '<span style="color:black;">' + (this.point.high - this.point.low).toFixed(1) + '</span>';
            };
        }

        if (recursiveIsset(chartObj, ['legend', 'labelFormatter'])) {
            chartObj.legend.labelFormatter = function () {
                if(this.series.chart.title.textStr == "Porównanie struktury akcjonariatu (2018/2019)"){
                    if (this.name.indexOf("2") !=-1) 
                    { 
                    temp = this.name.split("2");
                    return temp[0]; 
                    }
                    else { return this.name; }
                }
                // var result = this.point.high - this.point.low;
                // var length = 0;
                // if(this.series.chart.title.textStr == "Zmiany w stanie zatrudnienia w Grupie Kapitałowej Enea - osoby"||
                //     this.series.chart.title.textStr == "Przepływy pieniężne 2017"||
                //     this.series.chart.title.textStr == "Wydatki inwestycyjne<sup>1)</sup> GK ENEA 2017")
                // {
                //     result = result.toFixed(0);
                //     length = result.length-3;
                // }else{
                // result = result.toFixed(1);
                // length = result.length-5;
                // }
                // if(parseInt(result) > 1000){
                //     result = result.split('');
                    
                //     result.splice(length,0,' ');
                //     result = result.join('');
                // }
                // result = result.replace('.',','); 
                // return '<span style="font-size: 10px">' + this.key.name + '</span><br/><span style=\"color:' + this.point.color + '\">\u25CF</span><span>Wartość: ' + result + '</span>';
            
            }
        }

        //function: plotOptions.column.dataLabels.formatter
        if (recursiveIsset(chartObj, ['plotOptions', 'column', 'dataLabels', 'formatter'])) {
            chartObj.plotOptions.column.dataLabels.formatter = function () {
                // if (this.key.name == 'Stan na 31 grudnia 2016')
                // {
                //     var result = Math.floor((this.y / 18382.49800) * 1000)/10;
                //     return '<span>' + result + '%</span>';
                // } else if(this.key.name == 'Stan na 31 grudnia 2017') {
                //     var result = Math.round((this.y / 20416.24000000) * 1000)/10;
                //     return '<span>' + result + '%</span>';
                // }
            }
        }
        //function: tooltip.formatter
        if (recursiveIsset(chartObj, ['tooltip', 'formatter'])) {
            chartObj.tooltip.formatter = function () {
                // var result = this.point.high - this.point.low;
                // var length = 0;
                // if(this.series.chart.title.textStr == "Zmiany w stanie zatrudnienia w Grupie Kapitałowej Enea - osoby"||
                //     this.series.chart.title.textStr == "Przepływy pieniężne 2017"||
                //     this.series.chart.title.textStr == "Wydatki inwestycyjne<sup>1)</sup> GK ENEA 2017")
                // {
                //     result = result.toFixed(0);
                //     length = result.length-3;
                // }else{
                // result = result.toFixed(1);
                // length = result.length-5;
                // }
                // if(parseInt(result) > 1000){
                //     result = result.split('');
                    
                //     result.splice(length,0,' ');
                //     result = result.join('');
                // }
                // result = result.replace('.',','); 
                // return '<span style="font-size: 10px">' + this.key.name + '</span><br/><span style=\"color:' + this.point.color + '\">\u25CF</span><span>Wartość: ' + result + '</span>';
            
            }
        }

        //function: plotOptions.columnrange.dataLabels.formatter
        if (recursiveIsset(chartObj, ['plotOptions', 'columnrange', 'dataLabels', 'formatter'])) {
            var skip = chartObj.plotOptions.columnrange.dataLabels.formatter;
            chartObj.plotOptions.columnrange.dataLabels.formatter = function () {
               
                // var result = this.point.low - this.point.high;
                // var result2 = this.point.high-this.point.low;
                // lengthstr=0;

                // if(this.series.chart.title.textStr == "Zmiany w stanie zatrudnienia w Grupie Kapitałowej Enea - osoby"|| 
                //     this.series.chart.title.textStr == "Przepływy pieniężne 2017"||
                //     this.series.chart.title.textStr == "Wydatki inwestycyjne<sup>1)</sup> GK ENEA 2017")
                // {
                //     result = result.toFixed(0);
                //     result2 = result2.toFixed(0);
                //     lengthstr=3;
                // }else{
                //     result = result.toFixed(1);
                //     result2 = result2.toFixed(1);
                //     lengthstr=5;
                // }
                // if(result2 > 1000){
                //     result = result.split('');
                //     var length = result.length-lengthstr
                //     result.splice(length,0,' ');
                //     result = result.join('');
                    
                //     result2 = result2.split('');
                //     var length2 = result2.length-lengthstr
                //     result2.splice(length2,0,' ');
                //     result2 = result2.join('');
                // }

                // result = result.replace('.',',');
                // result2 = result2.replace('.',',');

                // if (skip.indexOf(this.key.name) >= 0) {
                //     if (this.y === this.point.low)
                //         return '<span style="color:black;">' + result + '</span>';
                // } else if (this.y === this.point.high)
                //     return '<span style="color:black;">' + result2 + '</span>';
            }
        }

        Highcharts.chart(chartElement, chartObj);
    } catch (e) {
        console.log(e);
    }
});


