countdownInit()

function countdownInit() {
  for(let i = 0; i < $('.countdown').length; i++) {
    const id = $('.countdown').eq(i).data('id')
    setTimeout(function() {
      countdown(id);
    }, i*300);
  }
}

function countdown(id){
  const start = $('.countdown[data-id="'+id+'"]').data('start');
	const stop = $('.countdown[data-id="'+id+'"]').data('stop');
	const duration = $('.countdown[data-id="'+id+'"]').data('duration');
	const interval = 50;
	const step = (stop - start) / (duration / interval);
	let i = parseInt(start);

	let counInterval = setInterval(function(){

		if(Number.isInteger(stop)){
			if (i < stop){
				i += Math.ceil(step);
			} else{ 
				i = stop;
				clearInterval(counInterval);
			}
			$('.countdown[data-id="'+id+'"]>#number').html(i.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")); 
		}else{
			if (i < stop){
				i += step;
			} else{ 
				i = stop;
				clearInterval(counInterval);
			}
			$('.countdown[data-id="'+id+'"]>#number').html(i.toFixed(1).toString().replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, " "));
		}
		
	}, interval);
}

Number.isInteger = Number.isInteger || function(value) {
	return typeof value === "number" && 
				 isFinite(value) && 
				 Math.floor(value) === value;
};
