import { tns } from 'tiny-slider/src/tiny-slider'

if($('.home-slider').length > 0) {
	var slider = tns({
		container: '.home-slider',
		slideBy: 'page',
		autoplay: true,
		items: 1,
		controlsPosition: 'bottom',
		nav: false,
		autoplayButtonOutput: false,
		autoplayTimeout: 15500,
		controlsText: [' ', ' '],
	});
}
