var undefined;
const CLIPBOARD = 'clipboard';

function addItem(item) {
    var clipboard = getItems();
    if (clipboard[item.slug] === undefined) {
        clipboard[item.slug] = item;
    }
    setItems(clipboard);
}
function removeItem(slug) {
    var clipboard = getItems();
    if (clipboard[slug] != undefined) {
        delete clipboard[slug];
    }
    setItems(clipboard);
}
function setItems(items) {
    if (items.undefined !== undefined) {
        delete items.undefined;
    }
    localStorage.setItem(CLIPBOARD, JSON.stringify(items));
}
function getItems() {
    var json = localStorage.getItem(CLIPBOARD);
    if (json) {
        return JSON.parse(json);
    }
    return {};
}
function clearItems() {
    localStorage.removeItem(CLIPBOARD);
}

$.fn.refreshClipboard = function() {
    var ul = $(this).find("[data-clipboard-list]");
    var inputs = $(this).find("[data-clipboard-inputs]");
    ul.html("");
    inputs.html("");
    var items = getItems();
    var length = 0;

    for (var i in items) {
        var li = $("<li>");
        var a = $('<a>').html(items[i].title).attr('href', items[i].url);
        var remove = $('<button>').attr('type', "button").html($('html').attr('lang') == 'pl' ? "Usuń" : "Remove").attr('data-remove-clipboard', items[i].slug);
        li.append(a);
        li.append(" ");
        li.append(remove);
        var inp = $("<input>").attr('type', 'hidden').attr('name', 'download[]').val(items[i].slug);
        inputs.append(inp);
        
        ul.append(li);
        length++;
    }
    if (length > 0) {
        $(this).find("[data-clipboard-container]").show();
        $(this).find("[data-clipboard-container-empty]").hide();
    } else {
        $(this).find("[data-clipboard-container]").hide();
        $(this).find("[data-clipboard-container-empty]").show();
    }
}

function showPopup(el) {
    $(el).show();
    $('.tooltip__itemOpen').removeClass("active");
    $(".overlay").fadeIn(800);

}
function hidePopup(el) {
    $(el).hide();
    $(".overlay").fadeOut(800);
}

$.fn.removeClipboard = function(slug) {
    removeItem(slug);
    $(this).refreshClipboard();
}

$.fn.clearClipboard = function() {
    clearItems();
    $(this).refreshClipboard();
}

$.fn.generateClipboard = function() {
    $(this).refreshClipboard();
    $('[data-clipboard-download]').trigger("submit");
}

$.fn.clipboard = (function () {
    var self = $(this);

    $(this).refreshClipboard();
    self.on("click", "[data-generate]", function (e) {
        $(self).generateClipboard();
        return false;
    });
    
    self.on("click", "[data-remove-clipboard]", function(){
        var slug = $(this).data("remove-clipboard");
        $(self).removeClipboard(slug);
        return false;
    });
    self.on("click", "[data-clear-clipboard]", function(){
        $(self).clearClipboard();
        return false;
    });

});

$(function () {
    $('#clipboard').clipboard();
    
    $("[data-add-clipboard]").on("click", function(e){
        e.preventDefault();
        var target = $(this).data('clipboard-target');
        var item = $(this).data("add-clipboard");
        addItem(item);
        $(target).refreshClipboard();
        $(target).openPopup();
    });
    
});
