let boxListId = [];

for(let i = 0; i < $('.box__list').length; i++) {
  boxListId.push($($('.box__list')[i]).attr('data-box-list-id'));
}

if(boxListId.length > 0){
  for(let i in boxListId){
    boxBorder(boxListId[i]);
  } 
}

function boxBorder(id) {
  let amountElements = $('.box__list[data-box-list-id="'+id+'"] .box__item').length;
  let borderBottom = amountElements % 3;
  let borderRight = 3;
  
  if($(window).outerWidth(true) > 1200 && $(window).outerWidth(true) < 1350 || $(window).outerWidth(true) > 575 && $(window).outerWidth(true) < 992 ) {
    borderBottom = amountElements % 2;
    borderRight = 2;
  }
  if($(window).outerWidth(true) < 576) {
    borderBottom = 1;
  }
  
  for(let i = 0; i < amountElements; i++) {
    if(borderBottom != 0) {
      if(i > (amountElements - borderBottom - 1)) {
        $($('.box__list[data-box-list-id="'+id+'"] .box__item')[i]).css('border-bottom', 'none');
      }
    } else {
      if(i > (amountElements - borderRight - 1)) {
        $($('.box__list[data-box-list-id="'+id+'"] .box__item')[i]).css('border-bottom', 'none');
      }
    }
    if(i % borderRight == borderRight - 1) {
      $($('.box__list[data-box-list-id="'+id+'"] .box__item')[i]).css('border-right', 'none');
    }
  }
}