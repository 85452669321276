$(".search__icon").on('click', function(){
	if($(".search__wrapper").hasClass("active")){
		$(".search__wrapper").removeClass("active").hide();
	}else{
		$(".search__wrapper").addClass("active").show();
	}
})

// $("body").on('click', function(e){
// 	if( !$(e.target).is('.search__icon') || !$(e.target).is('.search__input')){
// 		$(".search__wrapper").removeClass("active").hide();
// 	}
// })