//hide li when more than maxLi

$('#more-gri').on('click', function() {
    $('#hiddenGriList').fadeToggle();
});

//goto
$('a[href^="#"]').on('click', function() {
    let item = $(this).attr('href').split('#')[1];
    console.log(item)
    let targetId = $('.collapse .gri-link.'+item).closest('.collapse').attr('id');

    if($('#'+targetId).hasClass('show')){
        $('html, body').animate({
            scrollTop: $('.gri-link.'+item).offset().top
        }, 1000);
    }else{
        $("#"+targetId).collapse('show');
        $('#'+targetId).on('shown.bs.collapse', function () {
            $('html, body').animate({
                scrollTop: $('.gri-link.'+item).offset().top
            }, 1000);
        })
    }
});

//auto scroll and open
let url = window.location.href.split('#')[1];

if(typeof url != 'undefined'){
    let item = url;
    // let targetId = $('.collapse .gri-link.'+item).closest('.collapse').attr('id');
    let number = url.split('-')[1];
    let targetId = 'collapse-'+number;

    if($('#'+targetId).hasClass('show')){

    }else{
        $("#"+targetId).collapse('show');
        $('html, body').animate({
            scrollTop: $('#'+item).offset().top
        }, 1000);
    }
}