import { tns } from 'tiny-slider/src/tiny-slider'

if($('.slider__wrapper').length > 0) {
  for(let i = 1; i <= $('.slider__wrapper').length; i++) {
    const slider = tns({
      container: '.slider__wrapper[data-id="'+i+'"]',
      controls: false,
      slideBy: 'page',
      navPosition: 'bottom',
      autoplay: true,
      autoplayButtonOutput: false,	
      autoplayTimeout: 15500,
      speed: 1500,
    })
  }
}