$('.slider__options button').on('click', function(){
  let id = $(this).attr('data-id');
  if($('html').attr('lang') == 'en'){
    $('.slider__dotted--silver').html(bussinesModel[id].topen);
    $('.slider__dotted--orange').html(bussinesModel[id].bottomen);
  }else{
    $('.slider__dotted--silver').html(bussinesModel[id].top);
    $('.slider__dotted--orange').html(bussinesModel[id].bottom);
  }
  $('.slider__options button').removeClass('active');
  for(let i = 0; i < 4; i++){
    $('.slider__graphics--' + bussinesModel[i].class).css({'display':'none'});
  }
  if(!$(this).hasClass('active')){
    $(this).addClass('active');
    $('.slider__graphics--' + bussinesModel[id].class).css({'display':'block'});
  }
});

$('.bm-svg').on('click', function(){
  let id = $(this).attr('data-id');
  if($('html').attr('lang') == 'en'){
    $('.slider__dotted--silver').html(bussinesModel[id].topen);
    $('.slider__dotted--orange').html(bussinesModel[id].bottomen);
    $('.bm-svg').next().html(bussinesModel[id].nameen).css({'opacity':'0'});
  }else{
    $('.slider__dotted--silver').html(bussinesModel[id].top);
    $('.slider__dotted--orange').html(bussinesModel[id].bottom);
    $('.bm-svg').next().html(bussinesModel[id].name).css({'opacity':'0'});    
  }
  $('.bm-svg').prev().css({'opacity':'0'});
  $('.bm-svg').next().next().css({'opacity':'0'});
  $('.bm-svg').removeClass('active');
  if(!$(this).hasClass('active')){
    $(this).addClass('active');
    if($('html').attr('lang') == 'en'){
      $(this).next().html(bussinesModel[id].nameen).css({'opacity':'1'});
    }else{
      $(this).next().html(bussinesModel[id].name).css({'opacity':'1'});
    }
    $(this).prev().css({'opacity':'1'});
    $(this).next().next().css({'opacity':'1'});
  }
});

const bussinesModel = [
  {
    class:'one', 
    name:'kapitał naturalny',
    top:'<ul><li>200 tys. ton zakupionych surowców</li><li>563 tys. GJ zużytej energii el.</li><li>513 tys. Gj gazu ziemnego</li><li>770 tys. m sześc. zużytej wody</li></ul>', 
    bottom:'<p>28,8 tys. ton złomu aluminiowego poddanego recyklingowi</p><p>Spadek zużycia energii el. i gazu na jednostkę produkcji odpowiednio o 4% i 6%</p><p>Spadek zużycia wody na jednostkę produkcji o 3%</p><p>Spadek emisji gazów cieplarnianych o 5%</p>', 
    nameen:'Natural capital', 
    topen:'<ul><li>200 thousand tons of purchased raw materials</li><li>563 thousand GJ of energy consumed</li><li>513 thousand Gj of natural gas consumed</li><li>770 thousand cubic meters of used water</li></ul>',
    bottomen:'<p>28.8 thousand tons of recycled aluminum</p><p>Decrease of electricity and gas consumption per production unit by 4% and 6%, respectively</p><p>Decrease of water consumption per production unit by 3%</p><p>5% decrease in greenhouse gas emissions</p>'
  },
  
  {
    class:'two', 
    name:'aktywa produkcyjne', 
    top:'<ul><li>Rzeczowy majątek trwały - 1 439 mln zł</li><li>Majątek obrotowy - 1098 mln zł</li><li>Wydatki inwestycyjne 245,6 mln zł</li></ul>', 
    bottom:'<p>Przychody ze sprzedaży - 3,2 mld zł</p><p>ok 90% wykorzystanie mocy produkcyjnych</p><p>Zwrot z aktywów (ROA) - 11%</p>',
    nameen:'production assets', 
    topen:'<ul><li>Material fixed assets - PLN 1.439 million</li><li>Current assets - PLN 1.098 million</li><li>Investment expenses PLN 245,6 million</li></ul>',
    bottomen:'<p>Sales revenues - PLN 3.2 billion</p><p>ca. 90% capacity utilization</p><p>Return on assets (ROA) - 11%</p>'
  },

  {
    class:'three', 
    name:'ludzie wiedza i relacje', 
    top:'<ul><li>5196 pracowników</li><li>Ponad 4000 klientów</li><li>Ponad 3000 dostawców</li></ul>',
    bottom:'<p>44 tys. godzin szkoleniowych</p><p>456 mln zł wypłaconych świadczeń pracowniczych</p><p>101 tys. zł EBITDA na zatrudnionego</p>',
    nameen:'People, knowledge and relationships', 
    topen:'<ul><li>5196 employees</li><li>Over 4000 clients</li><li>Over 3000 suppliers</li></ul>', 
    bottomen:'<p>44 thousand hours of training</p><p>PLN 456 million of employee benefits paid</p><p>PLN 101 thousand of EBITDA per employee</p>'
  },

  {
    class:'four', 
    name:'finanse', 
    top:'<ul><li>Kapitał Własny - 1 479 mln zł</li><li>Zobowiązania bilansowe - 1 300 mln zł</li><li>Pomoc publiczna - zwolnienie z podatku dochodowego -  17,6 mln zł</li></ul>',
    bottom:'<p>Zwrot z kapitałów własnych (ROE) - 20%</p><p>Wypłacona dywidenda - 229 mln zł</p><p>Zapłacony w Polsce podatek VAT oraz CIT - 210 mln zł</p>',
    nameen:'Financial capital',  
    topen:'<ul><li>Equity capital - PLN 1.479 million</li><li>Liabilities -PLN 1.300 million</li><li>Public help - income tax exemption - PLN 17,6 million</li></ul>', 
    bottomen:'<p>Return on Equity (ROE) - 20%</p><p>Dividend paid - PLN 229 million</p><p>VAT and CIT paid in Poland - PLN 210 million</p>'
  }
]
