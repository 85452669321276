

$(window).scroll(function () {

	var menuTopPosition = $(".header").height();
	if (document.documentElement.scrollTop > menuTopPosition) {
		$('header.header').addClass('fixed-header');
	} else {
		$('header.header').removeClass('fixed-header');

	}
})