import Cookies from 'js-cookie';

const cookies = document.querySelector('.cookies');
const btn = document.querySelector('.cookies__accept');

if(Cookies.get('cookies') !== 'yes') {
  cookies.style.display = 'block';
}

btn.addEventListener('click', function() {
  Cookies.set('cookies', 'yes', { expires: 30 });
  cookies.style.display = 'none';
});
